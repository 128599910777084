@import "./variables";

.border {
  border-width: 1px;
  border-style: solid;
}

.border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.border-right {
  border-right-width: 1px;
  border-right-style: solid;
}

.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.border-left {
  border-left-width: 1px;
  border-left-style: solid;
}

.border-0 {
  border-width: 0;
}

.border-top-0 {
  border-top-width: 0;
}

.border-right-0 {
  border-right-width: 0;
}

.border-bottom-0 {
  border-bottom-width: 0;
}

.border-left-0 {
  border-left-width: 0;
}

.border-primary {
  border-color: $primary;
}

.border-primary-inverted {
  border-color: $primary-inverted;
}

.border-input {
  border-color: rgba(0, 0, 0, 0.38);
}
