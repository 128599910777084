@import "variables";

.text-primary {
  color: $primary;
}

.text-primary-inverted {
  color: $primary-inverted;
}

.text-accent {
  color: $accent;
}

.bg-primary {
  background-color: $primary;
}

.bg-activated-primary {
  background-color: rgba($primary, 0.12);
}

.bg-primary-lighter {
  background-color: $primary-lighter;
}

.bg-primary-darker {
  background-color: $primary-darker;
}

.bg-icon-active {
  background-color: rgba(#000000, 0.87);
}

.bg-icon-inactive {
  background-color: rgba(#000000, 0.6);
}

.bg-icon-disabled {
  background-color: rgba(#000000, 0.38);
}

.bg-hover {
  background-color: rgba(#000000, 0.04);
}

.bg-backdrop {
  background-color: #fafafa;
}

.bg-card {
  background-color: #ffffff;
}

.bg-input {
  background-color: #e8e8e8;
}

.text-success {
  color: #00c036;
}

.text-danger {
  color: #ff0c3e;
}

.text-dark {
  color: #004b4f;
}

.text-high-emphasis {
  opacity: 0.87;
}

.text-medium-emphasis {
  opacity: 0.6;
}

.text-disabled {
  opacity: 0.38;
}
