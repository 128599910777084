/* You can add global styles to this file, and also import other style files */
@import "~tippy.js/dist/tippy.css";
@import "~tippy.js/themes/light.css";

@import "styles/_border";
@import "styles/_color";
@import "styles/_spacing";
@import "styles/_flex";
@import "styles/_text";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

mat-card hr {
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

button.mat-button {
  text-transform: uppercase;
}
