.mt-4 {
  margin-top: 4px;
}

.mr-4 {
  margin-right: 4px;
}

.mr-n4 {
  margin-right: -4px;
}

.m-8 {
  margin: 8px;
}

.mt-8 {
  margin-top: 8px;
}

.mr-8,
button.mr-8 {
  margin-right: 8px;
}

.mb-8 {
  margin-bottom: 8px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-n8 {
  margin-left: -8px;
}

.m-16 {
  margin: 16px;
}

.mt-16 {
  margin-top: 16px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-n16 {
  margin-right: -16px;
}

.mb-16 {
  margin-bottom: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-n16 {
  margin-left: -16px;
}

.m-24 {
  margin: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-n24 {
  margin-right: -24px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-n24 {
  margin-bottom: -24px;
}

.ml-24 {
  margin-left: 24px;
}

.m-32 {
  margin: 32px;
}

.mt-32 {
  margin-top: 32px;
}

.mr-32 {
  margin-right: 32px;
}

.mb-32 {
  margin-bottom: 32px;
}

.ml-32 {
  margin-left: 32px;
}

.pt-0 {
  padding-top: 0;
}

.p-8 {
  padding: 8px;
}

.pt-8 {
  padding-top: 8px;
}

.pr-8 {
  padding-right: 8px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pl-8 {
  padding-left: 8px;
}

.p-16 {
  padding: 16px;
}

.pt-16 {
  padding-top: 16px;
}

.pr-16 {
  padding-right: 16px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pl-16 {
  padding-left: 16px;
}

.p-24 {
  padding: 24px;
}

.pt-24 {
  padding-top: 24px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pl-24 {
  padding-left: 24px;
}
