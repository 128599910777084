.text-uppercase {
  text-transform: uppercase;
}

.font-weight-activated {
  font-weight: 500;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-bold {
  font-weight: 500;
}

.user-select-none {
  user-select: none;
}
